.freecodecamp{
    width: 100%;
    height: 100%; 
}
.freecodecamp:hover{  
    content: url('../img/freecodecampHover.png');

}
.impar{ 
    background-image: url('../img/building.png');
    background-size: cover; 
}
.par{ 
    background-image: url('../img/building.jpg');
    background-size: cover; 
}

.impar .content, .par .content{ 
    background-color: rgba(1, 1, 1, 0.5); 
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.content span{
    font-size: 100px;
}

#simple-menu{
    position: absolute;
}

.content{
    display:flex;
    align-items: center;
    align-content: center;
}
.MuiStepConnector-vertical{
    margin-left: 25px!important;
}
.MuiStepLabel-root:hover{
    cursor: pointer!important;
}
.buttonWatchMore{
    animation-name: parpadeo;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes parpadeo {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}